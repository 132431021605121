import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">


<path d="M10695 22805 c-456 -73 -808 -435 -865 -888 -14 -114 -14 -11097 0
-11187 62 -394 321 -707 686 -830 189 -65 -269 -60 5794 -60 4910 0 5535 2
5619 15 411 67 726 354 839 764 l27 96 0 5615 0 5615 -27 98 c-115 416 -449
710 -871 767 -126 16 -11097 12 -11202 -5z m6542 -2612 c407 -67 745 -196
1050 -399 160 -107 254 -186 394 -332 289 -300 464 -635 589 -1125 64 -250 83
-405 84 -667 1 -258 -15 -401 -69 -615 -76 -303 -223 -601 -413 -840 -134
-168 -373 -386 -528 -481 -16 -9 -5 -34 113 -250 72 -131 350 -639 618 -1129
268 -489 492 -900 498 -913 8 -18 8 -25 -3 -32 -10 -6 -5 -22 24 -73 43 -77
44 -90 11 -122 l-24 -25 -1064 0 c-863 0 -1067 2 -1081 13 -10 7 -264 474
-565 1038 l-546 1024 -293 3 -292 2 0 -1019 0 -1020 -22 -21 c-22 -20 -29 -20
-984 -20 l-963 0 -20 26 -21 27 0 3423 0 3423 26 20 c15 12 35 21 45 21 16 0
19 8 19 45 l0 46 1643 -4 c1628 -3 1643 -3 1774 -24z"/>
<path d="M15710 18505 l0 -45 446 0 c485 0 582 -7 729 -55 185 -61 329 -167
425 -315 33 -51 40 -59 36 -35 -4 17 -23 64 -44 105 -54 111 -179 233 -293
289 -186 90 -281 101 -845 101 l-454 0 0 -45z"/>
<path d="M15740 17706 l0 -626 471 0 c437 0 478 2 572 21 229 46 424 175 476
313 70 187 44 444 -62 608 -82 128 -240 235 -410 280 -77 20 -110 22 -564 25
l-483 4 0 -625z"/>
<path d="M18824 16388 c-127 -157 -291 -306 -470 -425 -63 -42 -114 -79 -114
-83 0 -3 6 -15 14 -25 13 -18 17 -16 82 30 199 141 363 306 518 523 54 75 38
64 -30 -20z"/>
<path d="M15712 15453 l3 -48 343 -5 c330 -5 344 -6 355 -25 6 -11 255 -477
553 -1035 l541 -1015 972 -3 c534 -1 971 0 971 3 0 3 -9 24 -20 45 l-20 40
-955 0 c-710 0 -956 3 -962 12 -4 6 -256 475 -558 1042 l-550 1031 -338 3
-338 2 3 -47z"/>
<path d="M13860 13365 l0 -45 875 0 875 0 0 45 0 45 -875 0 -875 0 0 -45z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
